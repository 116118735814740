import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// users service
export const UserdApi = createApi({
    reducerPath: "user",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backmin.server.zavawholesale.com/",
        prepareHeaders: (headers, { getState }) => {
            const { userInfo: user } = getState().auth;
            if (user?.user?.accessToken) {
                headers.set("authorization", `Bearer ${user?.user?.accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        /* ===========  add department ===========  */
        getUser: builder.query({
            query: (pathname) => ({
                url: `userInfo?${pathname}`,
                method: "GET",
            }),
            providesTags: ['User']
        }),
        getSingleUser: builder.query({
            query: (id) => ({
                url: `userInfo/single?${id}`,
                method: "GET",
            }),
            invalidatesTags: ['User']
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: `userInfo`,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["User"],
        }),
        delete: builder.mutation({
            query: (data) => ({
                url: `userInfo`,
                method: "DELETE",
                body: data
            }),
            invalidatesTags: ["User"],
        }),

    }),
});
export const { useGetUserQuery, useUpdateUserMutation, useDeleteMutation,useGetSingleUserQuery } = UserdApi;

