import React, { Fragment, useMemo } from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import Pagination from '../../components/elements/Pagination'
import SingleProductList from '../../components/elements/product/SingleProductList'
import { useGetproductQuery } from '../../app/services/product'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom'
import GetSpinner from '../../helpers/shared/GetSpinner'
import { useState } from 'react'
import { useEffect } from 'react'
import ProductEdit from '../../components/elements/product/ProductEdit'
function AllProductShow() {
    /* paganitonm */
    const [search, setSearchValue] = useState('')
    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10, });
    const pathname = `page=${pageIndex}&limit=${pageSize}&search=${search}`;
    const [Loading, setLoading] = useState(false)
    const { data, isLoading } = useGetproductQuery(pathname)

    const ProductData = useMemo(() => (data ? data?.product : []), [
        data,
        search
    ]);
    /* modal */
    /* open modeal */
    const [open, setOpen] = useState({
        type: false,
        data: null
    })

    const [products, setProducts] = useState([]);
    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        setProducts(ProductData)
    }, [isLoading, ProductData])



    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedProducts = Array.from(products);
        const [movedProduct] = reorderedProducts.splice(result.source.index, 1);
        reorderedProducts.splice(result.destination.index, 0, movedProduct);
        setProducts(reorderedProducts);
    };

    return (
        <>

            <DashboardLayout setSearchValue={setSearchValue}>

                {!open.type &&
                    <section className="content-main">
                        <div className="content-header">
                            <div>
                                <h2 className="content-title card-title">Products List</h2>
                            </div>
                            <div>
                                <Link to="/addproduct/3" className="btn btn-primary btn-sm rounded">Create new</Link>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Product Name</th>
                                                        <th>Quantity</th>
                                                        <th>Cost</th>
                                                        <th>ofline</th>
                                                        <th>wholesale</th>
                                                        <th>Retailer</th>
                                                        <th>Consumer</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Loading && <GetSpinner />}
                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                        {products.map((data, index) => (
                                                            <Fragment key={data._id}>
                                                                <Droppable droppableId={data._id}>
                                                                    {(provided) => (
                                                                        <Fragment {...provided.droppableProps}>
                                                                            <Draggable draggableId={data._id} index={index}>
                                                                                {(provided) => (
                                                                                    <Fragment
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}
                                                                                    >
                                                                                        <SingleProductList data={data} setOpen={setOpen} />
                                                                                    </Fragment>
                                                                                )}
                                                                            </Draggable>
                                                                        </Fragment>
                                                                    )}
                                                                </Droppable>
                                                            </Fragment>
                                                        ))}
                                                    </DragDropContext>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Pagination
                            totalPages={data?.totalPages}
                            setPagination={setPagination}
                        />
                    </section>
                }
                {open.type && <ProductEdit modal={open} setOpen={setOpen} />}
            </DashboardLayout>

        </>
    )
}

export default AllProductShow