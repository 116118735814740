import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// users service
export const DashboardApi = createApi({
    reducerPath: "dashboard",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backmin.server.zavawholesale.com/",
        prepareHeaders: (headers, { getState }) => {
            const { userInfo: user } = getState().auth;
            if (user?.user?.accessToken) {
                headers.set("authorization", `Bearer ${user?.user?.accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        /* ===========  add product ===========  */
        getDashboard: builder.query({
            query: (data) => ({
                url: `dashboard?${data}`,
                method: "GET",
            }),
            providesTags: ['Dashboard']
        }),
    }),
});
export const { useGetDashboardQuery } = DashboardApi;

