import React, { useEffect } from 'react'
import item from '../../../assets/imgs/items/1.jpg'
import { useDeleteProductMutation, useUpdateProductMutation } from '../../../app/services/product'
import { handelClick } from '../../../utils/ConfirmDelete';
import { toast } from 'react-toastify';
function SingleProductList({ data, setOpen }) {
    const [deleteContent, { isLoading }] = useDeleteProductMutation()
    const showImages = data?.images[0]?.image?.[0];
    /*  */
    const [UpdateProduct, { isError, isLoading: statusLoading, isSuccess }] = useUpdateProductMutation()
    const handelStatusHandel = async (id, statusData) => {
        if (id && statusData) {
            const productUpdateValue = { _id: id, updateData: statusData }
            await UpdateProduct(productUpdateValue)
        }
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('status update ')
        }
        if (isError) {
            toast.error('status not changes')
        }
    }, [isSuccess, isError])
    return (
        <tr >
            <td>
                <div >
                    <a className="itemside" href="#">
                        <div className="left">
                            <img src={showImages ? showImages : item} className="img-sm img-thumbnail" alt="Item" />
                        </div>
                        <div className="info">
                            <h6 className="mb-0">{data?.product_name}</h6>
                        </div>
                    </a>
                </div>
            </td>
            <td>{data?.quantity}</td>
            <td>${data?.cost}
            </td>
            <td>${data?.saleing_Price}</td>
            <td>${data?.wholesaler_saleing_Price}</td>
            <td>${data?.retailer_saleing_Price}</td>
            <td>${data?.consumer_saleing_Price}</td>

            <td className="text-end">

                <div className="btn-group">
                    <a href="#" data-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm"
                        aria-haspopup="true" aria-expanded="false"
                    > <i className="material-icons md-more_horiz"></i> </a>

                    <div className="dropdown-menu px-2">
                        <span className='d-flex justify-start align-items-center gap-3  '>
                            Consumer
                            <i
                                onClick={() => handelStatusHandel(data?._id, { consumer_status: !data?.consumer_status })}
                                style={{
                                    fontSize: '30px',
                                    color: `${data?.consumer_status ? "#3BB77E" : "#f20a0a"}`,
                                    cursor: 'pointer'
                                }} className={`material-icons md-toggle_o${data?.consumer_status ? 'n' : 'ff'}`}></i>
                        </span>

                        <span
                            className='d-flex justify-start align-items-center gap-3 '
                        >
                            Retailer
                            <i
                                onClick={() => handelStatusHandel(data?._id, { retailer_status: !data?.retailer_status })}
                                style={{
                                    fontSize: '30px',
                                    color: `${data?.retailer_status ? "#3BB77E" : "#f20a0a"}`,
                                    cursor: 'pointer'
                                }}
                                className={`material-icons md-toggle_o${data?.retailer_status ? 'n' : 'ff'}`}></i></span>

                        <span
                            className='d-flex justify-start align-items-center gap-3 '
                        >
                            Wholesale
                            <i
                                onClick={() => handelStatusHandel(data?._id, { wholesaler_status: !data?.wholesaler_status })}
                                style={{
                                    fontSize: '30px',
                                    color: `${data?.wholesaler_status ? "#3BB77E" : "#f20a0a"}`,
                                    cursor: 'pointer'
                                }}
                                className={`material-icons md-toggle_o${data?.wholesaler_status ? 'n' : 'ff'}`}></i></span>

                        <a
                            onClick={() => setOpen({ type: true, data: data })}
                            className="btn btn-sm font-sm btn-light rounded mb-2"> <i className="material-icons md-edit"></i> Edit </a>
                        <br />
                        <a style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} onClick={() => handelClick(data?._id, deleteContent)} className="btn btn-sm font-sm btn-light rounded"> <i className="material-icons md-delete_forever"></i> Delete </a>
                    </div>
                </div>

            </td>
        </tr >
    )
}

export default SingleProductList