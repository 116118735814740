import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// blog service
export const OnlineOrderApi = createApi({
    reducerPath: "OnlineOrder",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backmin.server.zavawholesale.com/",
        prepareHeaders: (headers, { getState }) => {
            const { userInfo: user } = getState().auth;
            if (user?.user?.accessToken) {
                headers.set("authorization", `Bearer ${user?.user?.accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        /* ===========  add department ===========  */
        getOnlineOeder: builder.query({
            query: (pathname) => ({
                url: `onlineOrder?${pathname}`,
                method: "GET",
            }),
            providesTags: ['OnlineOrder']
        }),
        patchOnlineOeder: builder.mutation({
            query: ({ id, data }) => ({
                url: `onlineOrder/status?_id=${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["OnlineOrder"],
        }),
        deleteOnlineOeder: builder.mutation({
            query: (id) => ({
                url: `onlineOrder/delete?_id=${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["OnlineOrder"],
        }),
        getSingleOnlineOeder: builder.query({
            query: (id) => ({
                url: `onlineOrder/single?_id=${id}`,
                method: "GET",
            }),
            invalidatesTags: ["OnlineOrder"],
        }),

    }),
});
export const { useGetOnlineOederQuery, usePatchOnlineOederMutation, useDeleteOnlineOederMutation, useGetSingleOnlineOederQuery } = OnlineOrderApi;

