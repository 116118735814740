import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// blog service
export const BlogsApi = createApi({
    reducerPath: "blog",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backmin.server.zavawholesale.com/",
        prepareHeaders: (headers, { getState }) => {
            const { userInfo: user } = getState().auth;
            if (user?.user?.accessToken) {
                headers.set("authorization", `Bearer ${user?.user?.accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        /* ===========  add department ===========  */
        getBlog: builder.query({
            query: (pathname) => ({
                url: `blog?${pathname}`,
                method: "GET",
            }),
            providesTags: ['Blog']
        }),
        addBlog: builder.mutation({
            query: (data) => ({
                url: "blog",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Blog"],
        }),
        /* update Depatment */
        updateBlog: builder.mutation({
            query: (data) => ({
                url: `blog/update?_id=${data?.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Blog"],
        }),

        /* seleter Category */
        deleteBlog: builder.mutation({
            query: (id) => ({
                url: `blog/delete/?_id=${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Blog"],
        }),
        /* i need backuphelp insoallha i wi */
 
        backupCheck: builder.query({
            query: () => ({
                url: `backup`,
                method: "GET",
            }),
        }),

    }),
});
export const { useAddBlogMutation, useDeleteBlogMutation, useGetBlogQuery, useUpdateBlogMutation,useBackupCheckQuery} = BlogsApi;

