import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// blog service
export const SalesApi = createApi({
    reducerPath: "Sales",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backmin.server.zavawholesale.com/",
        prepareHeaders: (headers, { getState }) => {
            const { userInfo: user } = getState().auth;
            if (user?.user?.accessToken) {
                headers.set("authorization", `Bearer ${user?.user?.accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        /* ===========  add department ===========  */
        getOrderSales: builder.query({
            query: (pathname) => ({
                url: `sales?${pathname}`,
                method: "GET",
            }),
            providesTags: ['sales']
        }),
        getSingleOrderSales: builder.query({
            query: (id) => ({
                url: `sales/single?_id=${id}`,
                method: "GET",
            }),
            invalidatesTags: ["sales"],
        }),

    }),
});
export const { useGetOrderSalesQuery, useGetSingleOrderSalesQuery } = SalesApi;

