import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// users service
export const BrandApi = createApi({
    reducerPath: "brand",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backmin.server.zavawholesale.com/",
        prepareHeaders: (headers, { getState }) => {
            const { userInfo: user } = getState().auth;
            if (user?.user?.accessToken) {
                headers.set("authorization", `Bearer ${user?.user?.accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        /* ===========  add department ===========  */
        getbrand: builder.query({
            query: (pathname) => ({
                url: `brand?${pathname}`,
                method: "GET",
            }),
            providesTags: ['Brand']
        }),
        getAllbrand: builder.query({
            query: () => ({
                url: `brand/all`,
                method: "GET",
            }),
            invalidatesTags: ['Brand']
        }),
        addbrand: builder.mutation({
            query: (data) => ({
                url: "brand",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Brand"],
        }),
        /* update Depatment */
        updatebrand: builder.mutation({
            query: (data) => ({
                url: `brand/update?_id=${data?.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Brand"],
        }),

        /* seleter Category */
        deletebrand: builder.mutation({
            query: (id) => ({
                url: `brand/delete/?_id=${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Brand"],
        }),

    }),
});
export const {useGetbrandQuery, useAddbrandMutation, useUpdatebrandMutation, useDeletebrandMutation,useGetAllbrandQuery } = BrandApi;

